<template>
  <b-card>
    <validation-observer
      ref="infoRules"
      v-slot="{ invalid }"
      tag="form"
    >
      <b-row>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="Name"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="officeForm.name"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Country"
            rules="required"
          >
            <b-form-group
              label="Country"
              label-for="country"
            >
              <b-form-input
                id="country"
                v-model="officeForm.country"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Country"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="iban"
            rules="required"
          >
            <b-form-group
              label="iban"
              label-for="iban"
            >
              <b-form-input
                id="iban"
                v-model="officeForm.iban"
                :state="getValidationState(validationContext)"
                trim
                placeholder="iban"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Swift Code"
            rules="required"
          >
            <b-form-group
              label="Swift Code"
              label-for="Swift Code"
            >
              <b-form-input
                id="swift_code"
                v-model="officeForm.swift_code"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Swift Code"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Bank Account Number"
            rules="required"
          >
            <b-form-group
              label="Bank Account Number"
              label-for="Bank Account Number"
            >
              <b-form-input
                id="bank_account_number"
                v-model="officeForm.bank_account_number"
                :state="getValidationState(validationContext)"
                type="number"
                trim
                placeholder="Bank Account Number"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="Bank Account Name"
            rules="required"
          >
            <b-form-group
              label="Bank Account Name"
              label-for="Bank Account Name"
            >
              <b-form-input
                id="Bank Account Name"
                v-model="officeForm.bank_account_name"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Bank Account Name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <b-form-group
            label="Currency"
            label-for="Currency"
          >
            <v-select
              v-model="officeForm.currency"
              :options="currencyOptions"
              placeholder="Select Currency"
              label="text"
              :searchable="true"
            />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Check Name"
            rules="required"
          >
            <b-form-group
              label="Check Name"
              label-for="Check Name"
            >
              <b-form-input
                id="check_name"
                v-model="officeForm.check_name"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Check Name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="tax"
            rules="required"
          >
            <b-form-group
              label="Tax"
              label-for="Tax"
            >
              <b-form-input
                id="tax"
                v-model="officeForm.tax"
                type="number"
                :state="getValidationState(validationContext)"
                trim
                placeholder="tax"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">

          <validation-provider
            #default="validationContext"
            name="other_tax"
            rules="required"
          >
            <b-form-group
              label="Other Tax"
              label-for="Other Tax"
            >
              <b-form-input
                id="other Tax"
                v-model="officeForm.other_tax"
                type="number"
                :state="getValidationState(validationContext)"
                trim
                placeholder="other_tax"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <!-- Server Errors -->
        <b-col
          v-if="Object.values(errors).length > 0"
          cols="12"
        >
          <p
            v-for="(error, key, index) in errors"
            :key="index"
            class="text-danger small"
          >
            {{ error.toString() }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-if="!loader && $route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addOffice()"
          >
            Save Changes
          </b-button>
          <b-button
            v-if="!loader && !$route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addOffice()"
          >
            Add
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import axios from 'axios'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line no-unused-vars
import _ from 'lodash'
import VSelect from 'vue-select'
import decryptData from '@/store/auth/decryptData'

export default {
  components: {
    'v-select': VSelect, // Register vue-select component
  },
  data() {
    return {
      id: this.$store.state.generalIds.id,
      required,
      allSelected: [],
      users: [],
      loader: false,
      validationErrors: {},
      errors: {},
      currencyOptions: [],
    }
  },
  setup() {
    const { getValidationState } = formValidation()
    // eslint-disable-next-line camelcase

    const officeForm = ref({
    })
    return {
      getValidationState,
      officeForm,
    }
  },
  mounted() {
    this.showOffice()
    axios
      .get('https://gulftic-system.fci.group/api/currency/available')
      .then(res => {
        this.currencyOptions = decryptData(res.data.payload).data?.currencies?.map(currency => ({
          value: currency,
          text: currency,
        }))
      })
      .catch(error => {
        // console.error('Error fetching currencies:', error)
        if (error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'error fetching currencies',
              icon: 'BellIcon',
              variant: 'error',
            },
          })
        }
      })
  },
  methods: {
    showOffice() {
      if (this.$route.params.id) {
        axios.get(`office/${this.$route.params.id}`).then(res => {
          this.officeForm = decryptData(res.data.payload).data.office
        })
      } else {
        return false
      }
      return true
    },

    addOffice() {
      if (this.$route.params.id) {
        this.loader = true

        const formData = new FormData()
        formData.append('_method', 'PUT')

        // Use Object.entries() to iterate over the object's own properties
        Object.entries(this.officeForm).forEach(([key, value]) => {
          if (key === 'currency' && value && typeof value === 'object') {
            formData.append(key, value.value) // Adjust this depending on the structure of your currency object
          } else {
            formData.append(key, value)
          }
        })

        axios
          .post(`office/${this.$route.params.id}`, formData)
          .then(res => {
            if (res.status === 200) {
              this.$router.push({ name: 'office' })
              this.$toasted.show('Updated Successfully', {
                position: 'top-center',
                duration: 3000,
              })
            }
          })
          .catch(error => {
            if (error.response.status) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `${error.response.data.message}`,
                  icon: 'BellIcon',
                  variant: 'error',
                },
              })
            }
          })
          .finally(() => {
            this.loader = false
          })
      } else {
        this.loader = true
        const formData = new FormData()

        // Use Object.entries() to iterate over the object's own properties
        Object.entries(this.officeForm).forEach(([key, value]) => {
          if (key === 'currency' && value && typeof value === 'object') {
            formData.append(key, value.value) // Adjust this depending on the structure of your currency object
          } else {
            formData.append(key, value)
          }
        })

        axios
          .post('office', formData)
          .then(res => {
            if (res.status === 200 || res.status === 201) {
              this.$router.push({ name: 'office' })
              this.$toasted.show('Created Successfully', {
                position: 'top-center',
                duration: 3000,
              })
            }
          })
          .catch(error => {
            if (error.response.status) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `${error.response.data.message}`,
                  icon: 'BellIcon',
                  variant: 'error',
                },
              })
            }
          })
          .finally(() => {
            this.loader = false
          })
      }
    },

  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
